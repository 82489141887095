import React from "react";
import { HashLink } from "react-router-hash-link";

function HeroHome() {
  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <div className="mb-8 relative mx-auto inline-flex w-fit before:absolute before:inset-0">
              <a
                href="https://creatica.app"
                className="group relative inline-flex  w-full items-center justify-center rounded-full border border-blue-800/30 px-4   py-1 text-sm font-medium text-zinc-800 transition duration-150 ease-in-out before:pointer-events-none before:absolute before:inset-0 before:rounded-full hover:text-black"
              >
                Launching Creatica 🚀. Unlimited SVG Backgrounds
              </a>
            </div>
            <h1
              className="text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4 mr-2"
              data-aos="zoom-y-out"
            >
              Making
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-darkPurple to-darkPink">
                &nbsp; designs
              </span>
              &nbsp; simple for
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-lightLimoGreen to-darkLimoGreen">
                &nbsp; developers
              </span>
            </h1>
            <div className="max-w-3xl mx-auto my-16">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                We offer suite of simple & minimal design tools for developers
                to provide seamless design experience, without hassle.
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <HashLink
                    smooth
                    className="btn text-black focus:outline-black rounded-md w-full mb-4 sm:w-auto sm:mb-0"
                    to="#newsletter"
                  >
                    Learn more
                  </HashLink>
                </div>
                <div>
                  <HashLink
                    className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                    to="#design-tools-section"
                  >
                    Explore tools
                  </HashLink>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto rounded-md"
                  src={require("../images/ss.jpg")}
                  width="768"
                  height="432"
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
